import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [];

export const dictionary = {
		"/": [8],
		"/admin-dashboard": [9,[2]],
		"/admin-dashboard/shops": [10,[2]],
		"/admin-dashboard/shops/create": [11,[2]],
		"/admin-dashboard/users": [12,[2]],
		"/admin-dashboard/users/create": [13,[2]],
		"/admin-dashboard/users/edit/[user_id]": [14,[2]],
		"/admin-dashboard/webfonts": [15,[2]],
		"/admin-dashboard/webfonts/create": [16,[2]],
		"/admin-dashboard/webfonts/edit/[webfont_id]": [17,[2]],
		"/login": [18],
		"/shops": [19],
		"/shops/[shop_id]": [20,[3]],
		"/shops/[shop_id]/admin-preferences": [21,[3]],
		"/shops/[shop_id]/emails": [22,[3]],
		"/shops/[shop_id]/emails/[email_id]": [23,[3,4]],
		"/shops/[shop_id]/emails/[email_id]/variants": [24,[3,4]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]": [25,[3,4,5]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create": [26,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/discount-code": [27,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/error": [28,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/has-promotion": [29,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/images": [30,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/kind": [31,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/link": [32,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/notes": [33,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/preview": [34,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/products": [35,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/target": [36,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/topic": [37,[3,4,5,6]],
		"/shops/[shop_id]/settings": [38,[3]],
		"/shops/[shop_id]/templates": [39,[3,7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';